.home-container {
  display: flex;
  flex-direction: column; /* Change flex-direction to column */
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to full viewport height */
}

.token-container {
  margin-bottom: 10px;
  max-width: 600px;
  word-wrap: break-word;
}

  
.sign-button {
  /* Add any additional styling you want for your button */
  padding: 10px 20px;
  font-size: 18px;
  /* Add more styles as needed */
}

.login-button {
  /* Add any additional styling you want for your button */
  padding: 10px 20px;
  font-size: 18px;
  /* Add more styles as needed */
}